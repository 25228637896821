/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'organization-gradient': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.111 3.5c-.978 0-1.472.608-1.472 1.028h2.944c0-.42-.494-1.028-1.472-1.028zm0-1.5C6.634 2 5.14 2.984 5.14 4.528H2a1 1 0 00-1 1v7.778a1 1 0 001 1h12.222a1 1 0 001-1V5.528a1 1 0 00-1-1h-3.139C11.083 2.984 9.588 2 8.111 2zM2.5 9.722v3.084h11.222V9.722h-2.194v.14a.75.75 0 01-1.5 0v-.14H6.194v.14a.75.75 0 01-1.5 0v-.14H2.5zm9.028-1.5v-.139a.75.75 0 00-1.5 0v.14H6.194v-.14a.75.75 0 10-1.5 0v.14H2.5V6.027h11.222v2.194h-2.194z" _fill="url(#paint0_linear)"/><defs><linearGradient id="paint0_linear" x1="1.816" y1="2" x2="12.751" y2="15.857" gradientUnits="userSpaceOnUse"><stop stop-color="#B65DFC"/><stop offset="1" stop-color="#5863FC"/></linearGradient></defs>'
  }
})
